import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter as Router, Switch, Route, Redirect, Link } from 'react-router-dom'
import { browserHistory } from 'react-router'
import './base.css'
import Home from './demos/home'
import Error from './demos/error'

let title = ["HYPHAEIC",<br/>,"INTRAREALMS"]

function App() {
  return (
    <Router>
      <div className="frame">
        {/*<h1 className="frame__title">
          Recreating a <a href="https://twitter.com/beesandbombs/status/1329796242298245124">Dave Whyte</a> Animation <br />
          in React-Three-Fiber
        </h1>
        <div className="frame__links">
          <a href="https://tympanus.net/Development/HorizontalSmoothScrollLayout/">Previous demo</a>
          <a href="https://tympanus.net/codrops/?p=52356">Article</a>
          <a href="https://github.com/mattrossman/breathing-dots-tutorial">GitHub</a>
        </div>
        <div className="frame__author">
          Made by <a href="https://twitter.com/the_ross_man">Matt Rossman</a>
  </div>*/}
        <div className="frame__demos">
          <a href="https://my.forms.app/hyphaeic/hyphaeic-contact" target="_blank" rel="noopener noreferrer">CONTACT US</a>
        </div>
      </div>

      {/* below is the text for the center hero thing*/}
      <div className="content">
        <h2 className="content__title">{title}</h2>
      </div>

      <div id="animation">
        <Switch>
          <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={Home}/>
          <Route path="*" component={Error}/>
        </Switch>
      </div>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))
